import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RollbarProvider } from './components/providers/RollbarProvider.js';
import { TwilioSyncProvider } from './components/providers/TwilioSyncProvider';
import hashHistory from './history';
import configureStore from './store/configureStore';

// constants
import { UploadADocRoutes } from './common/constants';

// components
import { MediaQueryProvider, ViewportHeightProvider } from '@flk/design-system';
import { ToastContainer } from './components/toast_container/ToastContainer.tsx';
import ErrorPage from './containers/ErrorPage';
import Main from './containers/Main';
import CustomFallback from './containers/custom_document/CustomFallback.tsx';
import Maintenance from './containers/maintenance/Maintenance';
import SplashScreen from './containers/steps/SplashScreen';

//common
const Welcome = lazy(() => import('./containers/common/Welcome'));
const Signing = lazy(() => import('./containers/steps/Signing'));
const Summary = lazy(() => import('./components/steps/Summary'));
const ConfirmLocation = lazy(() => import('./containers/common/ConfirmLocation'));
const Finish = lazy(() => import('./components/steps/Finish'));
const PropertyAddress = lazy(() => import('./containers/common/PropertyAddress'));
const AgreementHandler = lazy(() => import('./containers/steps/AgreementHandler'));
const WitnessSigning = lazy(() => import('./containers/steps/WitnessSigning'));
const MobileSigningLogin = lazy(() => import('./containers/pm_steps/sa/MobileSigningLogin.tsx'));

//Residential lease
const PersonalDetails = lazy(() => import('./containers/steps/PersonalDetails'));
const Inclusions = lazy(() => import('./containers/steps/Inclusions'));
const TermDates = lazy(() => import('./containers/steps/TermDates'));
const PaymentDetails = lazy(() => import('./containers/steps/PaymentDetails'));
const Occupants = lazy(() => import('./containers/steps/Occupants'));
const Emergency = lazy(() => import('./containers/steps/Emergency'));
const Strata = lazy(() => import('./containers/steps/Strata'));
const Defence = lazy(() => import('containers/steps/DefencePolicy'));
const MaterialFacts = lazy(() => import('./containers/steps/MaterialFacts'));
const Pause = lazy(() => import('./components/steps/Pause'));
const AdditionalTerms = lazy(() => import('./components/steps/AdditionalTerms'));
const Connections = lazy(() => import('containers/steps/Connections'));

// Res ten VIC and ACT common
const CommonAddress = lazy(() => import('./containers/steps/common/Address'));
const Tenant = lazy(() => import('./containers/steps/common/Tenant'));
const Rent = lazy(() => import('./containers/steps/common/Rent'));
const Bond = lazy(() => import('./containers/steps/common/Bond'));
const EmergencyContacts = lazy(() => import('./containers/steps/common/EmergencyContacts'));
const CommonPaymentDetails = lazy(() => import('./containers/steps/common/PaymentDetails'));

// Res ten VIC
const RentalProvider = lazy(() => import('./containers/steps/VIC/RentalProvider'));
const ElectronicService = lazy(() => import('./containers/steps/common/ElectronicService'));
const TermOfAgreement = lazy(() => import('./containers/steps/VIC/TermOfAgreement'));
const ProfessionalCleaning = lazy(() => import('./containers/steps/VIC/ProfessionalCleaning'));
const OwnersCorporation = lazy(() => import('./containers/steps/VIC/OwnersCorporation'));
const ConditionReport = lazy(() => import('./containers/steps/VIC/ConditionReport'));
const TermsConditions = lazy(() => import('./containers/steps/VIC/TermsConditions'));
const VicDefence = lazy(() => import('./containers/steps/VIC/DefencePolicy'));

//Res ten ACT
const Lessor = lazy(() => import('./containers/steps/ACT/Lessor'));
const ActElectronicService = lazy(() => import('./containers/steps/ACT/ElectronicService'));
const ActInclusions = lazy(() => import('./containers/steps/ACT/Inclusions'));
const ActRent = lazy(() => import('./containers/steps/ACT/Rent'));
const ClausesEnabled = lazy(() => import('./containers/steps/ACT/ClausesEnabled'));
const ActTermOfAgreement = lazy(() => import('./containers/steps/ACT/TermOfAgreement'));
const ActTermsConditions = lazy(() => import('./containers/steps/ACT/TermsConditions'));

//Res ten SA
const AgentLandlordAndTenant = lazy(() => import('./containers/steps/SA/AgentLandlordAndTenant'));
const SaTermOfAgreement = lazy(() => import('./containers/steps/SA/TermOfAgreement'));
const SaRent = lazy(() => import('./containers/steps/SA/Rent'));
const SaRentIncrease = lazy(() => import('./containers/steps/SA/RentIncrease'));
const Outgoings = lazy(() => import('./containers/steps/SA/Outgoings'));
const RepairsAndMaintenance = lazy(() => import('./containers/steps/SA/RepairsAndMaintenance'));
const Disclosure = lazy(() => import('./containers/steps/SA/Disclosure'));
const ExecutedAsAgreement = lazy(() => import('./containers/steps/SA/ExecutedAsAgreement'));
const SaTermsConditions = lazy(() => import('./containers/steps/SA/TermsConditions'));
const PetAnnexure = lazy(() => import('./containers/steps/SA/PetAnnexure'));
const Instructions = lazy(() => import('./containers/steps/SA/Instructions'));

const SaRenewalTenant = lazy(() => import('./containers/steps/SA/RenewalTenant'));
const SaRentAndTerm = lazy(() => import('./containers/steps/SA/SaRentAndTerm'));
const ClauseVariations = lazy(() => import('./containers/steps/SA/ClauseVariations'));

//Sales
const OwnerDetails = lazy(() => import('./containers/sales_steps/OwnerDetails'));
const AgentDetails = lazy(() => import('./containers/sales_steps/AgentDetails'));
const SaleMethod = lazy(() => import('./containers/sales_steps/SaleMethod'));
const Marketing = lazy(() => import('./containers/sales_steps/Marketing'));
const SalesTerms = lazy(() => import('./containers/sales_steps/SalesTerms'));
const SalesInclusions = lazy(() => import('./containers/sales_steps/SalesInclusions'));
const OwnerId = lazy(() => import('./containers/sales_steps/OwnerId'));
const Rebates = lazy(() => import('./containers/sales_steps/Rebates'));
const SalesMaterialFacts = lazy(() => import('./containers/sales_steps/SalesMaterialFact'));
const AppointmentOfProperty = lazy(() => import('./containers/sales_steps/QLD/AppointmentOfProperty'));
const TerminationOfAppointment = lazy(() => import('./containers/sales_steps/QLD/TerminationOfAppointment'));
const QldSaleMethod = lazy(() => import('./containers/sales_steps/QLD/SaleMethod'));
const Commission = lazy(() => import('./containers/sales_steps/QLD/Commission'));
const QldMarketing = lazy(() => import('./containers/sales_steps/QLD/Marketing'));
const FeesAndCharges = lazy(() => import('./containers/sales_steps/QLD/FeesAndCharges'));
const QldRebates = lazy(() => import('./containers/sales_steps/QLD/Rebates'));
const ManagingAgent = lazy(() => import('./containers/sales_steps/QLD/ManagingAgent'));
const SolicitorDetails = lazy(() => import('./containers/sales_steps/QLD/SolicitorDetails'));
const AuctionDetails = lazy(() => import('./containers/sales_steps/QLD/AuctionDetails'));
const SalesQldMaterialFacts = lazy(() => import('./containers/sales_steps/QLD/SalesMaterialFact'));
const Insurance = lazy(() => import('./containers/sales_steps/QLD/Insurance'));
const SalesOutgoings = lazy(() => import('./containers/sales_steps/QLD/Outgoings'));
const PropertyDescription = lazy(() => import('./containers/sales_steps/QLD/PropertyDescription'));
const PropertyDescriptionPart2 = lazy(() => import('./containers/sales_steps/QLD/PropertyDescriptionPart2'));
const SalesQldWelcome = lazy(() => import('./containers/sales_steps/QLD/Welcome'));

//PM
const PropertyDetailsRepairs = lazy(() => import('./containers/pm_steps/PropertyDetailsRepairs'));
const RentTerm = lazy(() => import('./containers/pm_steps/RentTerm'));
const PmPaymentDetails = lazy(() => import('./containers/pm_steps/PaymentDetails'));
const PmFees = lazy(() => import('./containers/pm_steps/Fees'));
const pmAdvertisingPromotionRebates = lazy(() => import('./containers/pm_steps/pmAdvertisingPromotionRebates'));
const PmExpenses = lazy(() => import('./containers/pm_steps/Expenses'));
const PmInsurance = lazy(() => import('./containers/pm_steps/qld/PmInsurance'));
const PmOutgoings = lazy(() => import('./containers/pm_steps/Outgoings'));
const PmEmergencyContacts = lazy(() => import('./containers/pm_steps/qld/PmEmergencyContacts'));
const WarrantyAndMaintenance = lazy(() => import('./containers/pm_steps/qld/WarrantyAndMaintenance'));
const PoolSafetyAndSmokeAlarms = lazy(() => import('./containers/pm_steps/qld/PoolSafetyAndSmokeAlarms'));
const PriorAppointment = lazy(() => import('./containers/pm_steps/qld/PriorAppointment'));
const PmMarketing = lazy(() => import('./containers/pm_steps/PmMarketing'));
const PmOwnerId = lazy(() => import('./containers/pm_steps/PmOwnerId'));
const DisclosureOfInformation = lazy(() => import('./containers/pm_steps/PmDisclosureOfInformation'));
const MaterialFact = lazy(() => import('./containers/pm_steps/PmMaterialFact'));
const DynamicStandardTerms = lazy(() => import('./containers/pm_steps/PmDynamicStandardTerms'));
const PmOtherServices = lazy(() => import('./containers/pm_steps/OtherServices'));
const PmRebates = lazy(() => import('./containers/pm_steps/PmRebates'));
const Schedule2 = lazy(() => import('./containers/pm_steps/Schedule2'));
const WaterConsumptionAndTenantPayments = lazy(() => import('./containers/pm_steps/WaterConsumptionAndTenantPayments'));

// PM QLD
const PmQldWelcome = lazy(() => import('./containers/pm_steps/qld/Welcome'));

// PM VIC
const ClientDetails = lazy(() => import('./containers/pm_steps/vic/ClientDetails'));
const AgentFee = lazy(() => import('./containers/pm_steps/vic/AgentFee'));
const AdditionalCost = lazy(() => import('./containers/pm_steps/vic/AdditionalCost'));
const PmVicPaymentMethods = lazy(() => import('./containers/pm_steps/vic/PaymentMethods'));
const PmRepairs = lazy(() => import('./containers/pm_steps/vic/Repairs'));
const RebateAndCommission = lazy(() => import('./containers/pm_steps/vic/RebateAndCommission'));
const PmVicServicesSchedule = lazy(() => import('./containers/pm_steps/vic/ServicesSchedule'));

// SA PM
const PmSaWelcome = lazy(() => import('./containers/pm_steps/sa/Welcome.tsx'));
const PmSaLocation = lazy(() => import('./containers/pm_steps/sa/Location.tsx'));
const Landlord = lazy(() => import('./containers/pm_steps/sa/Landlord.tsx'));
const PmSaPropertyAddress = lazy(() => import('./containers/pm_steps/sa/PropertyAddress.tsx'));
const PmSaTerm = lazy(() => import('./containers/pm_steps/sa/Term.tsx'));
const PmSaFees = lazy(() => import('./containers/pm_steps/sa/Fees.tsx'));
const PmSaRent = lazy(() => import('./containers/pm_steps/sa/Rent.tsx'));
const PmSaMarketingAndAdvertising = lazy(() => import('./containers/pm_steps/sa/MarketingAndAdvertising.tsx'));
const PmSaOutgoings = lazy(() => import('./containers/pm_steps/sa/Outgoings.tsx'));
const PmSaPaymentMethods = lazy(() => import('./containers/pm_steps/sa/PaymentMethods.tsx'));
const PmSaEmergencyContact = lazy(() => import('./containers/pm_steps/sa/EmergencyContact.tsx'));
const PmSaDuties = lazy(() => import('./containers/pm_steps/sa/Duties.tsx'));
const PmSaInsurance = lazy(() => import('./containers/pm_steps/sa/Insurance.tsx'));
const PmSaCurrentInsurance = lazy(() => import('./containers/pm_steps/sa/CurrentInsurance.tsx'));
const PmSaAdditionalAcknowledgement = lazy(() => import('./containers/pm_steps/sa/AdditionalAcknowledgement.tsx'));
const PmSaAdditionalTerms = lazy(() => import('./containers/pm_steps/sa/AdditionalTerms.tsx'));
const PmSaTermsAndConditions = lazy(() => import('./containers/pm_steps/sa/TermsAndConditions.tsx'));
const PmSaOwnerId = lazy(() => import('./containers/pm_steps/sa/OwnerId.tsx'));
const PmSaInstructions = lazy(() => import('./containers/pm_steps/sa/Instructions.tsx'));
const PmSaConfirmation = lazy(() => import('./containers/pm_steps/sa/Confirmation.tsx'));
const PmSaSigning = lazy(() => import('./containers/pm_steps/sa/Signing.tsx'));
const PmSaSummary = lazy(() => import('./containers/pm_steps/sa/Summary.tsx'));
const Thankyou = lazy(() => import('./containers/pm_steps/sa/Thankyou.tsx'));

//Sales - PM
const AdditionalTermsQuestions = lazy(() => import('./components/steps/AdditionalTermsQuestions'));

//Stand alone
const CustomDocument = lazy(() => import('./containers/custom_document/CustomDocument.tsx'));
const CustomWelcome = lazy(() => import('./containers/custom_document/CustomWelcome.tsx'));
const SelfServeWelcome = lazy(() => import('./containers/custom_document/self-serve/Welcome'));
const SelfServeDetails = lazy(() => import('./containers/custom_document/self-serve/Details'));
const SelfServeErrorPage = lazy(() => import('./containers/custom_document/self-serve/ErrorPage'));
const ClientCustomPlaceholdersForm = lazy(() =>
    import('./containers/custom_document/ClientCustomPlaceholdersForm.tsx')
);
const CustomDocumentContainer = lazy(() => import('./containers/custom_document/CustomDocumentContainer.tsx'));

const RentReduction = lazy(() => import('./containers/rent_reduction/RentReduction'));
const RentReductionConditions = lazy(() => import('./containers/rent_reduction/RentReductionConditions'));
const RentReductionDetails = lazy(() => import('./containers/rent_reduction/RentReductionDetails'));
const InformationStatement = lazy(() => import('./containers/statement/InformationStatement'));
const SignDocument = lazy(() => import('./containers/SignDocument'));
const DocumentCompleted = lazy(() => import('./containers/common/DocumentCompleted'));
const Auth = lazy(() => import('./containers/Auth'));
const DisclosureDocumentWelcome = lazy(() => import('./containers/disclosure/DisclosureDocumentWelcome'));
const DisclosureDocumentDetails = lazy(() => import('./containers/disclosure/DisclosureDocumentDetails'));
const DisclosureDocumentDisclosures = lazy(() => import('./containers/disclosure/DisclosureDocumentDisclosures'));
const DisclosureDocumentCompleted = lazy(() => import('./containers/disclosure/DisclosureDocumentCompleted'));
const CreateFlkWelcome = lazy(() => import('./containers/createFlk/CreateFlkWelcome'));
const CreateFlkClauses = lazy(() => import('./containers/createFlk/CreateFlkClauses'));
const CreateFlkDetails = lazy(() => import('./containers/createFlk/CreateFlkDetails'));
const CreateFlkCompleted = lazy(() => import('./containers/createFlk/CreateFlkCompleted'));

const FlkAKeyImageUpload = lazy(() => import('./containers/flk_a_key/FlkAKeyImageUpload'));

// Commercial Lease
const CommercialLeaseClientDetails = lazy(() => import('./containers/commercial_lease/NSW/ClientDetails'));
const CommercialLeaseTerm = lazy(() => import('./containers/commercial_lease/NSW/Term'));
const CommercialLeaseRent = lazy(() => import('./containers/commercial_lease/NSW/Rent'));
const CommercialLeaseInformation = lazy(() => import('./containers/commercial_lease/NSW/Information'));

// middleware
//add actions
import 'actions/index';
import 'sass/desktopSign.scss';
import 'sass/global.scss';
import { initVendor } from 'vendor/vendor';
import { configureAxios } from './actions';
import Preloader from './components/Preloader';
import NewPreloader from './components/new_designs/Preloader.tsx';

// initialise vendor scripts
initVendor();

//store
let store = configureStore();
configureAxios(store);

const renderLoader = () => <Preloader />;
const renderLoaderForWhiteScreen = () => <NewPreloader className="margin-top-5-rem" />;

const harnessIOTarget = { identifier: 'signature-app-user' };

/**
 * Please refer to this page for explanation to defaults: https://flkitover.atlassian.net/wiki/spaces/BRI/pages/1703837697/TanStack+Query+Usage
 */
const queryClientConfig = {
    defaultOptions: {
        queries: {
            retry: 2,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            cacheTime: 1000 * 60 * 5, //5 minutes
            staleTime: 1000 * 60 * 5, //5 minutes
        },
        mutations: {
            retry: 2,
        },
    },
};

// Create a tanstack client
const queryClient = new QueryClient(queryClientConfig);

ReactDOM.render(
    <Provider store={store}>
        <RollbarProvider>
            <QueryClientProvider client={queryClient}>
                <FFContextProvider async apiKey={HARNESS_IO_API_KEY} target={harnessIOTarget}>
                    <TwilioSyncProvider>
                        <ConnectedRouter history={hashHistory}>
                            <ToastContainer>
                                <ViewportHeightProvider>
                                    <MediaQueryProvider>
                                        <Switch>
                                            <Route exact path="/maintenance" component={Maintenance} />
                                            <Route exact path="/" component={SplashScreen} />
                                            <Route exact path="/error" component={ErrorPage} />
                                            <Route exact path="/:token" component={SplashScreen} />
                                            <Route
                                                path="/steps"
                                                render={() => {
                                                    return (
                                                        <Main>
                                                            <Route path="/steps/pause" component={Pause} />
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route path="/steps/welcome" component={Welcome} />
                                                                <Route
                                                                    path="/steps/agreement"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/steps/address"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route path="/steps/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/steps/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route path="/steps/summary" component={Summary} />
                                                                <Route
                                                                    path="/steps/tenantSignature"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/steps/details"
                                                                    component={PersonalDetails}
                                                                />
                                                                <Route
                                                                    path="/steps/inclusions"
                                                                    component={Inclusions}
                                                                />
                                                                <Route path="/steps/terms" component={TermDates} />
                                                                <Route
                                                                    path="/steps/payment"
                                                                    component={PaymentDetails}
                                                                />
                                                                <Route path="/steps/occupants" component={Occupants} />
                                                                <Route path="/steps/emergency" component={Emergency} />
                                                                <Route path="/steps/strata" component={Strata} />
                                                                <Route path="/steps/defence" component={Defence} />
                                                                <Route
                                                                    path="/steps/materialFact"
                                                                    component={MaterialFacts}
                                                                />
                                                                 <Route
                                                                    path="/steps/witness-signing"
                                                                    component={WitnessSigning}
                                                                />
                                                                <Route
                                                                    path="/steps/specialConditionsInitial"
                                                                    component={AdditionalTerms}
                                                                />
                                                                <Route
                                                                    path="/steps/connections"
                                                                    component={Connections}
                                                                />
                                                            </Suspense>
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route path="/steps/VIC/welcome" component={Welcome} />
                                                                <Route path="/steps/VIC/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/steps/VIC/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route path="/steps/VIC/summary" component={Summary} />
                                                                <Route
                                                                    path="/steps/VIC/tenantSignature"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/address"
                                                                    component={CommonAddress}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/details"
                                                                    component={RentalProvider}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/rentersDetails"
                                                                    component={Tenant}
                                                                />
                                                                <Route path="/steps/VIC/rent" component={Rent} />
                                                                <Route path="/steps/VIC/bond" component={Bond} />
                                                                <Route
                                                                    path="/steps/VIC/emergency"
                                                                    component={EmergencyContacts}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/payment"
                                                                    component={CommonPaymentDetails}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/electronicService"
                                                                    component={ElectronicService}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/terms"
                                                                    component={TermOfAgreement}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/professionalCleaning"
                                                                    component={ProfessionalCleaning}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/ownersCorporation"
                                                                    component={OwnersCorporation}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/conditionReport"
                                                                    component={ConditionReport}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/agreement"
                                                                    component={TermsConditions}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/defence"
                                                                    component={VicDefence}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/connections"
                                                                    component={Connections}
                                                                />
                                                                <Route
                                                                    path="/steps/VIC/specialConditionsInitial"
                                                                    component={AdditionalTerms}
                                                                />
                                                            </Suspense>

                                                            <Suspense fallback={renderLoader()}>
                                                                <Route path="/steps/ACT/welcome" component={Welcome} />
                                                                <Route path="/steps/ACT/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/steps/ACT/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/address"
                                                                    component={CommonAddress}
                                                                />
                                                                <Route path="/steps/ACT/summary" component={Summary} />
                                                                <Route
                                                                    path="/steps/ACT/tenantSignature"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/tenantsDetails"
                                                                    component={Tenant}
                                                                />
                                                                <Route path="/steps/ACT/bond" component={Bond} />
                                                                <Route
                                                                    path="/steps/ACT/emergency"
                                                                    component={EmergencyContacts}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/payment"
                                                                    component={CommonPaymentDetails}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/professionalCleaning"
                                                                    component={ProfessionalCleaning}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/ownersCorporation"
                                                                    component={OwnersCorporation}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/conditionReport"
                                                                    component={ConditionReport}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/defence"
                                                                    component={VicDefence}
                                                                />
                                                                <Route path="/steps/ACT/details" component={Lessor} />
                                                                <Route
                                                                    path="/steps/ACT/actElectronicService"
                                                                    component={ActElectronicService}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/inclusions"
                                                                    component={ActInclusions}
                                                                />
                                                                <Route path="/steps/ACT/rent" component={ActRent} />
                                                                <Route
                                                                    path="/steps/ACT/clausesEnabled"
                                                                    component={ClausesEnabled}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/terms"
                                                                    component={ActTermOfAgreement}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/specialConditionsInitial"
                                                                    component={AdditionalTerms}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/connections"
                                                                    component={Connections}
                                                                />
                                                                <Route
                                                                    path="/steps/ACT/agreement"
                                                                    component={ActTermsConditions}
                                                                />
                                                            </Suspense>

                                                            <Suspense fallback={renderLoader()}>
                                                                <Route path="/steps/SA/welcome" component={Welcome} />
                                                                <Route
                                                                    path="/steps/SA/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/address"
                                                                    component={CommonAddress}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/landlord"
                                                                    component={AgentLandlordAndTenant}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/tenant"
                                                                    component={SaRenewalTenant}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/terms"
                                                                    component={SaTermOfAgreement}
                                                                />
                                                                <Route path="/steps/SA/rent" component={SaRent} />
                                                                <Route
                                                                    path="/steps/SA/rentAndTerm"
                                                                    component={SaRentAndTerm}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/clauseVariations"
                                                                    component={ClauseVariations}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/rentIncrease"
                                                                    component={SaRentIncrease}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/payment"
                                                                    component={CommonPaymentDetails}
                                                                />
                                                                <Route path="/steps/SA/bond" component={Bond} />
                                                                <Route
                                                                    path="/steps/SA/outgoings"
                                                                    component={Outgoings}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/emergency"
                                                                    component={RepairsAndMaintenance}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/disclosure"
                                                                    component={Disclosure}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/executedAsAgreement"
                                                                    component={ExecutedAsAgreement}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/agreement"
                                                                    component={SaTermsConditions}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/petAnnexure"
                                                                    component={PetAnnexure}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/instructions"
                                                                    component={Instructions}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/specialConditionsInitial"
                                                                    component={AdditionalTerms}
                                                                />
                                                                <Route
                                                                    path="/steps/SA/tenantSignature"
                                                                    component={Signing}
                                                                />
                                                                <Route path="/steps/SA/summary" component={Summary} />
                                                                <Route
                                                                    path="/steps/SA/connections"
                                                                    component={Connections}
                                                                />
                                                                <Route path="/steps/SA/woohoo" component={Finish} />
                                                            </Suspense>
                                                        </Main>
                                                    );
                                                }}
                                            />

                                            <Route
                                                path="/sales-steps"
                                                render={() => {
                                                    return (
                                                        <Main>
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route
                                                                    path="/sales-steps/welcome"
                                                                    component={Welcome}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/standard_terms_conditions"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/property_details"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route path="/sales-steps/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/sales-steps/signing_location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/summary"
                                                                    component={Summary}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/owner_signs"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/agent_conditions"
                                                                    component={AdditionalTermsQuestions}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/owner_details"
                                                                    component={OwnerDetails}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/agent_details"
                                                                    component={AgentDetails}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/method_of_sale"
                                                                    component={SaleMethod}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/marketing"
                                                                    component={Marketing}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/terms"
                                                                    component={SalesTerms}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/inclusions"
                                                                    component={SalesInclusions}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/rebates"
                                                                    component={Rebates}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/material_fact"
                                                                    component={SalesMaterialFacts}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/owner_id"
                                                                    component={OwnerId}
                                                                />
                                                            </Suspense>

                                                            <Suspense fallback={renderLoader()}>
                                                                <Route
                                                                    path="/sales-steps/QLD/welcome"
                                                                    component={SalesQldWelcome}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/signing_location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/owner_details"
                                                                    component={OwnerDetails}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/property_details"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/appointment_of_property"
                                                                    component={AppointmentOfProperty}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/termination_of_appointment"
                                                                    component={TerminationOfAppointment}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/method_of_sale"
                                                                    component={QldSaleMethod}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/commission"
                                                                    component={Commission}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/marketing"
                                                                    component={QldMarketing}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/fees_and_charges"
                                                                    component={FeesAndCharges}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/rebates"
                                                                    component={QldRebates}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/managing_agent"
                                                                    component={ManagingAgent}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/agent_details"
                                                                    component={SolicitorDetails}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/auction_details"
                                                                    component={AuctionDetails}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/material_fact"
                                                                    component={SalesQldMaterialFacts}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/insurance"
                                                                    component={Insurance}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/outgoings"
                                                                    component={SalesOutgoings}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/property_description"
                                                                    component={PropertyDescription}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/property_description_part_2"
                                                                    component={PropertyDescriptionPart2}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/standard_terms_conditions"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/agent_conditions"
                                                                    component={AdditionalTermsQuestions}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/owner_id"
                                                                    component={OwnerId}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/summary"
                                                                    component={Summary}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/owner_signs"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/sales-steps/QLD/woohoo"
                                                                    component={Finish}
                                                                />
                                                            </Suspense>
                                                        </Main>
                                                    );
                                                }}
                                            />
                                            <Route
                                                path="/pm-steps"
                                                render={() => {
                                                    return (
                                                        <Main>
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route path="/pm-steps/welcome" component={Welcome} />
                                                                <Route
                                                                    path="/pm-steps/pmStandardTerms"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/address"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route path="/pm-steps/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/pm-steps/signing_location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route path="/pm-steps/summary" component={Summary} />
                                                                <Route
                                                                    path="/pm-steps/owner_signs"
                                                                    component={Signing}
                                                                />
                                                                <Route path="/pm-steps/ownerId" component={PmOwnerId} />
                                                                <Route
                                                                    path="/pm-steps/pmDetailsRepairs"
                                                                    component={PropertyDetailsRepairs}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmRentAndTerm"
                                                                    component={RentTerm}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/paymentMethod"
                                                                    component={PmPaymentDetails}
                                                                />
                                                                <Route path="/pm-steps/pmFees" component={PmFees} />
                                                                <Route
                                                                    path="/pm-steps/pmAdvertisingPromotionRebates"
                                                                    component={pmAdvertisingPromotionRebates}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmExpensesAndCharges"
                                                                    component={PmExpenses}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmInsurance"
                                                                    component={PmInsurance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmOutgoings"
                                                                    component={PmOutgoings}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmEmergencyContact"
                                                                    component={PmEmergencyContacts}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/warrantyAndMaintenance"
                                                                    component={WarrantyAndMaintenance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmPoolSafetyAndSmokeAlarms"
                                                                    component={PoolSafetyAndSmokeAlarms}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/priorAppointment"
                                                                    component={PriorAppointment}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmMarketingAndAdvertising"
                                                                    component={PmMarketing}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmDisclosureOfInformation"
                                                                    component={DisclosureOfInformation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmMaterialFact"
                                                                    component={MaterialFact}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmDynamicTermsConditions"
                                                                    component={DynamicStandardTerms}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmOtherServices"
                                                                    component={PmOtherServices}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmRebates"
                                                                    component={PmRebates}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/pmSchedule2"
                                                                    component={Schedule2}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/waterConsumptionAndTenantPayments"
                                                                    component={WaterConsumptionAndTenantPayments}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/agentConditions"
                                                                    component={AdditionalTermsQuestions}
                                                                />
                                                            </Suspense>
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route
                                                                    path="/pm-steps/QLD/welcome"
                                                                    component={PmQldWelcome}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmStandardTerms"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/address"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route path="/pm-steps/QLD/woohoo" component={Finish} />
                                                                <Route
                                                                    path="/pm-steps/QLD/signing_location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/summary"
                                                                    component={Summary}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/owner_signs"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/ownerId"
                                                                    component={PmOwnerId}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmDetailsRepairs"
                                                                    component={PropertyDetailsRepairs}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmRentAndTerm"
                                                                    component={RentTerm}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/paymentMethod"
                                                                    component={PmPaymentDetails}
                                                                />
                                                                <Route path="/pm-steps/QLD/pmFees" component={PmFees} />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmAdvertisingPromotionRebates"
                                                                    component={pmAdvertisingPromotionRebates}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmExpensesAndCharges"
                                                                    component={PmExpenses}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmInsurance"
                                                                    component={PmInsurance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmOutgoings"
                                                                    component={PmOutgoings}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmEmergencyContact"
                                                                    component={PmEmergencyContacts}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/warrantyAndMaintenance"
                                                                    component={WarrantyAndMaintenance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmPoolSafetyAndSmokeAlarms"
                                                                    component={PoolSafetyAndSmokeAlarms}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/priorAppointment"
                                                                    component={PriorAppointment}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmMarketingAndAdvertising"
                                                                    component={PmMarketing}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmDisclosureOfInformation"
                                                                    component={DisclosureOfInformation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmMaterialFact"
                                                                    component={MaterialFact}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmDynamicTermsConditions"
                                                                    component={DynamicStandardTerms}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmOtherServices"
                                                                    component={PmOtherServices}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmRebates"
                                                                    component={PmRebates}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/pmSchedule2"
                                                                    component={Schedule2}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/waterConsumptionAndTenantPayments"
                                                                    component={WaterConsumptionAndTenantPayments}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/QLD/agentConditions"
                                                                    component={AdditionalTermsQuestions}
                                                                />
                                                            </Suspense>

                                                            <Suspense fallback={renderLoader()}>
                                                                <Route
                                                                    path="/pm-steps/VIC/welcome"
                                                                    component={Welcome}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmDetailsRepairs"
                                                                    component={ClientDetails}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/address"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmRentAndTerm"
                                                                    component={RentTerm}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmAgentFee"
                                                                    component={AgentFee}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmAdditionalCost"
                                                                    component={AdditionalCost}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmOutgoings"
                                                                    component={PmOutgoings}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/paymentMethod"
                                                                    component={PmVicPaymentMethods}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmRebates"
                                                                    component={RebateAndCommission}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmRepairs"
                                                                    component={PmRepairs}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmStandardTerms"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/pmServicesSchedule"
                                                                    component={PmVicServicesSchedule}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/ownerId"
                                                                    component={PmOwnerId}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/ownerSigns"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/summary"
                                                                    component={Summary}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/VIC/agentConditions"
                                                                    component={AdditionalTermsQuestions}
                                                                />
                                                                <Route path="/pm-steps/VIC/woohoo" component={Finish} />
                                                            </Suspense>

                                                            <Suspense fallback={renderLoaderForWhiteScreen()}>
                                                                <Route
                                                                    path="/pm-steps/SA/welcome"
                                                                    component={PmSaWelcome}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/location"
                                                                    component={PmSaLocation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/landlord"
                                                                    component={Landlord}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/address"
                                                                    component={PmSaPropertyAddress}
                                                                />
                                                                <Route path="/pm-steps/SA/term" component={PmSaTerm} />
                                                                <Route path="/pm-steps/SA/rent" component={PmSaRent} />
                                                                <Route
                                                                    path="/pm-steps/SA/pmFees"
                                                                    component={PmSaFees}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/pmMarketingAndAdvertising"
                                                                    component={PmSaMarketingAndAdvertising}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/pmOutgoings"
                                                                    component={PmSaOutgoings}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/paymentMethod"
                                                                    component={PmSaPaymentMethods}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/pmEmergencyContact"
                                                                    component={PmSaEmergencyContact}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/pmDuties"
                                                                    component={PmSaDuties}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/insurance"
                                                                    component={PmSaInsurance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/currentInsurance"
                                                                    component={PmSaCurrentInsurance}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/additionalAcknowledgement"
                                                                    component={PmSaAdditionalAcknowledgement}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/pmStandardTerms"
                                                                    component={PmSaTermsAndConditions}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/agentConditions"
                                                                    component={PmSaAdditionalTerms}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/ownerId"
                                                                    component={PmSaOwnerId}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/instructions"
                                                                    component={PmSaInstructions}
                                                                />
                                                                <Route
                                                                    path={[
                                                                        '/pm-steps/SA/confirmation',
                                                                        '/pm-steps/SA/ownerSigns',
                                                                        '/pm-steps/SA/summary',
                                                                    ]}
                                                                    component={PmSaConfirmation}
                                                                />
                                                                <Route
                                                                    path="/pm-steps/SA/woohoo"
                                                                    component={Thankyou}
                                                                />
                                                            </Suspense>
                                                        </Main>
                                                    );
                                                }}
                                            />
                                            <Route
                                                path="/sign"
                                                render={() => {
                                                    return (
                                                        <Suspense fallback={renderLoaderForWhiteScreen()}>
                                                            <Route path="/sign/:token" component={MobileSigningLogin} />
                                                        </Suspense>
                                                    );
                                                }}
                                            />
                                            <Route
                                                path="/commercial-lease-steps"
                                                render={() => {
                                                    return (
                                                        <Main>
                                                            <Suspense fallback={renderLoader()}>
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/welcome"
                                                                    component={Welcome}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/location"
                                                                    component={ConfirmLocation}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/details"
                                                                    component={CommercialLeaseClientDetails}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/address"
                                                                    component={PropertyAddress}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/term"
                                                                    component={CommercialLeaseTerm}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/rent"
                                                                    component={CommercialLeaseRent}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/information"
                                                                    component={CommercialLeaseInformation}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/agreement"
                                                                    component={AgreementHandler}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/specialConditionsInitial"
                                                                    component={AdditionalTerms}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/tenantSignature"
                                                                    component={Signing}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/witnessSigning"
                                                                    component={WitnessSigning}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/summary"
                                                                    component={Summary}
                                                                />
                                                                <Route
                                                                    path="/commercial-lease-steps/NSW/woohoo"
                                                                    component={Finish}
                                                                />
                                                            </Suspense>
                                                        </Main>
                                                    );
                                                }}
                                            />
                                            <Route
                                                path="/rent-reduction"
                                                render={() => {
                                                    return (
                                                        <Suspense fallback={renderLoader()}>
                                                            <Route
                                                                exact
                                                                path="/rent-reduction/:token"
                                                                component={RentReduction}
                                                            />
                                                            <Route
                                                                path="/rent-reduction/content/details"
                                                                component={RentReductionDetails}
                                                            />
                                                            <Route
                                                                path="/rent-reduction/content/conditions"
                                                                component={RentReductionConditions}
                                                            />
                                                        </Suspense>
                                                    );
                                                }}
                                            />
                                            <Suspense fallback={renderLoader()}>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/disclosure-document/completed"
                                                        component={DisclosureDocumentCompleted}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/disclosure-document/disclosures"
                                                        component={DisclosureDocumentDisclosures}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/disclosure-document/details"
                                                        component={DisclosureDocumentDetails}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/disclosure-document/:token"
                                                        component={DisclosureDocumentWelcome}
                                                    />
                                                </Switch>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/create-a-flk/completed"
                                                        component={CreateFlkCompleted}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/create-a-flk/clauses"
                                                        component={CreateFlkClauses}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/create-a-flk/details"
                                                        component={CreateFlkDetails}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/create-a-flk/:token"
                                                        component={CreateFlkWelcome}
                                                    />
                                                </Switch>
                                                <Suspense fallback={<CustomFallback />}>
                                                    <Route path="/custom-document/:token">
                                                        <CustomDocumentContainer>
                                                            <Route
                                                                exact
                                                                path={UploadADocRoutes.DETAILS}
                                                                component={CustomDocument}
                                                            />
                                                            <Route
                                                                exact
                                                                path={UploadADocRoutes.SIGN}
                                                                component={CustomDocument}
                                                            />
                                                            <Route
                                                                exact
                                                                path={UploadADocRoutes.FORM}
                                                                component={ClientCustomPlaceholdersForm}
                                                            />
                                                            <Route
                                                                exact
                                                                path={UploadADocRoutes.WELCOME}
                                                                component={CustomWelcome}
                                                            />
                                                        </CustomDocumentContainer>
                                                    </Route>
                                                </Suspense>
                                                <Route path="/statement/:token" component={InformationStatement} />
                                                <Route path="/auth" component={Auth} />
                                                <Switch>
                                                    <Route
                                                        path="/sign-document/completed"
                                                        component={DocumentCompleted}
                                                    />
                                                    <Route path="/sign-document/:token" component={SignDocument} />
                                                </Switch>
                                                <Switch>
                                                    <Route
                                                        path="/upload-image/flk-a-key/:token"
                                                        component={FlkAKeyImageUpload}
                                                    />
                                                </Switch>
                                                <Switch>
                                                    <Route path="/self-serve/error" component={SelfServeErrorPage} />
                                                    <Route
                                                        path="/self-serve/:templateId/details"
                                                        component={SelfServeDetails}
                                                    />
                                                    <Route
                                                        path="/self-serve/:templateId"
                                                        component={SelfServeWelcome}
                                                    />
                                                </Switch>
                                            </Suspense>
                                            <Route component={SplashScreen} />
                                        </Switch>
                                    </MediaQueryProvider>
                                </ViewportHeightProvider>
                            </ToastContainer>
                        </ConnectedRouter>
                    </TwilioSyncProvider>
                </FFContextProvider>
            </QueryClientProvider>
        </RollbarProvider>
    </Provider>,
    document.getElementById('root')
);
