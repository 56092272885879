import React from 'react';
import Preloader from '../../components/Preloader.js';

import styles from './CustomFallback.module.scss';

const CustomFallback = () => (
    <div className={styles.container}>
        <Preloader />
    </div>
);

export default CustomFallback;
