import 'sass/footer.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import hashHistory from './../history';

import * as tenantLogin from '../actions/main';

import Button from 'common/components/Button';
import ConfirmLocationFooter from 'containers/steps/ConfirmLocationFooter';
import DefencePolicyFooter from 'containers/steps/DefencePolicyFooter';
import AdditionalTermsFooter from 'containers/steps/AdditionalTermsFooter';
import MaterialFactsFooter from 'containers/steps/MaterialFactsFooter';
import ConnectionsFooter from 'containers/steps/ConnectionsFooter';
import SigningFooter from 'containers/steps/SigningFooter';
import CommercialLeaseSigningFooter from 'containers/steps/CommercialLeaseSigningFooter';
import ReadAndAgreeFooter from 'containers/steps/ReadAndAgreeFooter';
import { SPECIAL_CONDITIONS_INITIAL_STEP, DISCLOSURE_STEP } from '../common/constants';
import { VIC_STATE, SA_STATE } from '../config';
import ElectronicServiceFooter from '../containers/steps/common/ElectronicServiceFooter';
import OwnerCorporationFooter from '../containers/steps/VIC/OwnerCorporationFooter';
import SignFooter from '../containers/steps/common/SignFooter';
import { getUrlStepType, isLeaseTypeCommercialLease, isLocationBaseURL } from '../utils';
import WitnessSigningFooter from '../containers/steps/WitnessSigningFooter';

class Footer extends Component {
    footerContent() {
        const {
            user,
            steps,
            step,
            currentStepStatus,
            stepDataLocal,
            isConfirmingStep,
            lease,
            inventoryPdfAcknowledged,
        } = this.props;
        let currentStep = steps[step];
        let neverDefaultFooter = [
            'welcome', // Always agree to terms
            'emergency', // Give users the ability to add contacts
            'summary', // Final page
            'woohoo',
            'pause',
        ];

        const defaultFooter = (disableNext = false) => (
            <footer className={currentStepStatus}>
                {currentStepStatus === 'declined' && <div className="button button-cancel" />}
                {currentStepStatus === 'declined' && <div className="button button-success" />}
                {currentStepStatus === 'none' && (
                    <div className="button button-cancel">
                        <button onClick={() => this.props.declineButtonHandler()} disabled={isConfirmingStep}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus === 'none' && !disableNext && (
                    <div className="button button-success">
                        <button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                    <div className="button button-prev">
                        <button onClick={() => this.toStep(step - 1, steps)}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                    <div className="button button-next">
                        <button onClick={() => this.toStep(step + 1, steps)}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
            </footer>
        );

        if (currentStep.status === 'confirmed' && neverDefaultFooter.indexOf(currentStep.name) === -1) {
            return defaultFooter();
        }

        switch (currentStep.name) {
            case 'welcome': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            Get Started
                        </Button>
                        <p>
                            By clicking "Get Started" you agree to FLK IT OVER'S{' '}
                            <a
                                href="http://www.flkitover.com/terms-conditions/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                terms and conditions
                            </a>{' '}
                            and{' '}
                            <a
                                href="http://www.flkitover.com/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                privacy policy
                            </a>
                        </p>
                    </div>
                );
            }
            case 'location': {
                return (
                    <ConfirmLocationFooter
                        confirmButtonHandler={this.props.confirmButtonHandler}
                        enterLocationManually={this.props.enterLocationManually}
                    />
                );
            }
            case 'address': {
                if (isLeaseTypeCommercialLease(lease.leaseType)) {
                    return inventoryPdfAcknowledged ? defaultFooter() : null;
                }
                return defaultFooter();
            }
            case 'emergency': {
                const uri = SERVICE_URL + '/api/tenant/export-emergency-contacts';
                return (
                    <div>
                        <footer className={currentStepStatus}>
                            {currentStepStatus === 'none' && (
                                <div className="button button-cancel">
                                    <button type="submit" onClick={() => this.props.declineButtonHandler()}>
                                        <div className="icon" />
                                    </button>
                                </div>
                            )}
                            {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                                <div className="button button-prev">
                                    <button onClick={() => this.toStep(step - 1, steps)}>
                                        <div className="icon" />
                                    </button>
                                </div>
                            )}
                            {currentStepStatus !== 'declined' && (
                                <div className="button button-middle button-download-contacts">
                                    <a className="link" rel="noopener noreferrer" href={uri} target="_blank">
                                        <div className="icon" />
                                    </a>
                                </div>
                            )}
                            {currentStepStatus === 'none' && (
                                <div className="button button-success">
                                    <button
                                        type="submit"
                                        onClick={() => this.props.confirmButtonHandler()}
                                        disabled={isConfirmingStep}
                                    >
                                        <div className="icon" />
                                    </button>
                                </div>
                            )}
                            {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                                <div className="button button-next">
                                    <button onClick={() => this.toStep(step + 1, steps)}>
                                        <div className="icon" />
                                    </button>
                                </div>
                            )}
                        </footer>
                    </div>
                );
            }
            case 'defence': {
                if (currentStep.status !== 'confirmed') {
                    return <DefencePolicyFooter confirmButtonHandler={this.props.confirmButtonHandler} />;
                } else {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Proceed
                            </Button>
                        </div>
                    );
                }
            }
            case 'electronicService': {
                if (currentStep.status !== 'confirmed') {
                    return (
                        <ElectronicServiceFooter
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                        />
                    );
                } else {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Proceed
                            </Button>
                        </div>
                    );
                }
            }
            case 'ownersCorporation': {
                if (currentStep.status !== 'confirmed') {
                    return (
                        <OwnerCorporationFooter
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                        />
                    );
                } else {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Proceed
                            </Button>
                        </div>
                    );
                }
            }
            case 'materialFact': {
                let disableSigning = false;
                if (currentStep.status !== 'confirmed') {
                    if (stepDataLocal && stepDataLocal[step].disablePageSigning) {
                        disableSigning = true;
                    }
                    return (
                        <div className="footer-buttons-relative">
                            <MaterialFactsFooter
                                confirmButtonHandler={this.props.confirmButtonHandler}
                                disableSigning={disableSigning}
                                currentStep={currentStep}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className="footer-buttons-relative">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Proceed
                            </Button>
                        </div>
                    );
                }
            }
            case DISCLOSURE_STEP: {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I Agree
                        </Button>
                    </div>
                );
            }
            case 'instructions': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I acknowledge
                        </Button>
                    </div>
                );
            }
            case 'petAnnexure': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I’ve read and agree to this!
                        </Button>
                    </div>
                );
            }
            case 'executedAsAgreement': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I agree
                        </Button>
                    </div>
                );
            }
            case SPECIAL_CONDITIONS_INITIAL_STEP: {
                let disableSigning = false;
                if (currentStep.status !== 'confirmed') {
                    if (stepDataLocal && stepDataLocal[step].disablePageSigning) {
                        disableSigning = true;
                    }
                    if (isLeaseTypeCommercialLease(lease.leaseType) && lease.specialConditions.hasSpecialConditions) {
                        return (
                            <ReadAndAgreeFooter
                                step={step}
                                steps={steps}
                                currentStep={currentStep}
                                confirmButtonHandler={this.props.confirmButtonHandler}
                                label={'I’ve read and agree to this!'}
                                disabled={isConfirmingStep || disableSigning}
                            />
                        );
                    }
                    if ([SA_STATE, VIC_STATE].includes(this.props.location)) {
                        return (
                            <div className="footer-buttons">
                                <Button
                                    onClick={() => this.props.confirmButtonHandler()}
                                    disabled={isConfirmingStep || disableSigning}
                                >
                                    Next
                                </Button>
                            </div>
                        );
                    }
                    return (
                        <div className="footer-buttons-relative">
                            <AdditionalTermsFooter
                                confirmButtonHandler={this.props.confirmButtonHandler}
                                disableSigning={disableSigning}
                                currentStep={currentStep}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className="footer-buttons-relative">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Proceed
                            </Button>
                        </div>
                    );
                }
            }
            case 'tenantSignature': {
                if (isLeaseTypeCommercialLease(lease.leaseType)) {
                    return (
                        <CommercialLeaseSigningFooter
                            step={step}
                            steps={steps}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                        />
                    );
                }
                return (
                    <SigningFooter
                        step={step}
                        steps={steps}
                        isConfirmingStep={isConfirmingStep}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'agreement': {
                if (isLeaseTypeCommercialLease(lease.leaseType) && lease.specialConditions.hasSpecialConditions) {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                I’ve read and agree to this!
                            </Button>
                        </div>
                    );
                }
                return (
                    <ReadAndAgreeFooter
                        step={step}
                        steps={steps}
                        currentStep={currentStep}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                        label={'I’ve read and agree to this!'}
                    />
                );
            }
            case 'witnessSigning': {
                return (
                    <WitnessSigningFooter
                        step={step}
                        steps={steps}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'summary': {
                let preloader = '';
                let disableButton = false;

                return (
                    <div className="footer-buttons">
                        <Button
                            onClick={() => this.props.confirmButtonHandler()}
                            disabled={disableButton || isConfirmingStep}
                        >
                            FLK it back to your agent {preloader}
                        </Button>
                    </div>
                );
            }
            case 'connections':
                return <ConnectionsFooter confirmButtonHandler={this.props.confirmButtonHandler} />;
            case 'woohoo':
            case 'pause': {
                return <footer />;
            }
            case 'details': {
                if (user?.shouldCompleteEmergencyContact) return defaultFooter(true);
                return defaultFooter();
            }
            default: {
                return defaultFooter();
            }
        }
    }

    toStep(step, steps) {
        const stepType = getUrlStepType(this.props.lease.leaseType);
        if (isLocationBaseURL(this.props.lease.leaseType, this.props.location)) {
            hashHistory.push(`/${stepType}/${this.props.location}/${steps[step].name}`);
        } else {
            hashHistory.push(`/${stepType}/${steps[step].name}`);
        }
        this.props.setCurrentStep(steps[step], this.props.lease);
    }

    render() {
        const appIsLoadingClass = this.props.appIsLoading ? 'hide' : '';
        return <div className={appIsLoadingClass}>{this.footerContent()}</div>;
    }
}

export default connect(
    (state) => ({
        stepDataLocal: state.main.steps,
        location: state.main.lease.location,
        isConfirmingStep: state.steps.isConfirmingStep,
        lease: state.main.lease,
        appIsLoading: state.main.appIsLoading,
        inventoryPdfAcknowledged: state.commercialLeaseQuestions.inventoryPdfAcknowledged,
    }),
    {
        setCurrentStep: tenantLogin.setCurrentStep,
    }
)(Footer);
